import { isPlatformBrowser } from '@angular/common'
import { Injectable, Inject, PLATFORM_ID } from '@angular/core'
import { AppSettings } from '@configs/app.setting'
import { SidebarService } from '@layouts/pages/sidebar/sidebar.service'
import { AdminBusinessDetail } from 'src/app/admin/business/ui/admin-business.model'
import { UserInfo } from '@core/models/common.model'
import { Role, UserContent } from '@core/models/user.model'
import { AdminBusinessService } from '@services/admin-business/admin-business.service'
import { CommonService } from '@services/common/common.service'
// import { CookieService } from 'ngx-cookie'
import { CustomCookieService } from '@services/cookie/cookie.service'
import { getCurrTimezone, setCurrTimezone } from '@utils/app-stuff'
import { getLastLogin, setMerchantType } from '@utils/auth-stuff'
import { setBusinessType } from '@utils/business'
import { CountryList } from '@utils/country'
import { BehaviorSubject } from 'rxjs'
import { environment } from 'src/environments/environment'
import { AdminBusinessRespositoryService } from 'src/app/admin/business/data-access/repository/admin-business-repository/admin-business-respository.service'

@Injectable()
export class AuthService {
  readonly AUTH_LEVEL: string = 'authLevel'
  readonly ADMIN_PRIVILAGE: string = 'admin-privilage'
  readonly APP_VERSION: string = AppSettings.APP_VERSION
  isBrowser: boolean = false

  constructor(
    @Inject(PLATFORM_ID) platformId: Object,
    private _cookieService: CustomCookieService,
    private _commonService: CommonService,
    private _businessService: AdminBusinessService,
    private _businessRepository: AdminBusinessRespositoryService,
    private _sidebarService: SidebarService
  ) {
    this.isBrowser = isPlatformBrowser(platformId)
  }

  setNameInitial(name: string) {
    localStorage.setItem(AppSettings.NAME_INITIAL, name.charAt(0))
  }

  getNameInitial() {
    return localStorage.getItem(AppSettings.NAME_INITIAL)
  }

  setBusinessName(businessName: string) {
    localStorage.setItem(AppSettings.BUSINESS_NAME, businessName)
  }

  getBusinessName() {
    return localStorage.getItem(AppSettings.BUSINESS_NAME)
  }

  setRoleName(roleName: string) {
    localStorage.setItem(AppSettings.ROLE_NAME, roleName)
  }

  getRoleName() {
    return localStorage.getItem(AppSettings.ROLE_NAME)
  }

  setAuthLevel(authLevel: string) {
    localStorage.setItem(this.AUTH_LEVEL, authLevel)
  }

  getAuthLevel() {
    return localStorage.getItem(this.AUTH_LEVEL)
  }

  setRole(role: Role) {
    const setRole: string | any = JSON.stringify(role)
    localStorage.setItem(AppSettings.ROLE, setRole)
  }

  getRole(): Role {
    let role: Role = new Role()
    const roleJSON: string | null = localStorage.getItem(AppSettings.ROLE)
    return (role = JSON.parse(roleJSON as string))
  }

  fullName(firstName: string, lastName: string) {
    localStorage.setItem(AppSettings.FIRST_NAME_STORAGE, firstName)
    if (lastName) localStorage.setItem(AppSettings.LAST_NAME_STORAGE, lastName)
  }

  login(token: string) {
    localStorage.setItem(AppSettings.TOKEN_STORAGE, token)
  }

  userKey(token: string) {
    localStorage.setItem(AppSettings.USER_KEY, token)
  }

  deviceId(token: string) {
    localStorage.setItem(AppSettings.DEVICE_ID, token)
  }

  deviceKey(token: string) {
    localStorage.setItem(AppSettings.DEVICE_KEY, token)
  }

  email(email = '') {
    localStorage.setItem(AppSettings.EMAIL, email)
  }

  getEmail(): any {
    return localStorage.getItem(AppSettings.EMAIL)
  }

  setMerchantId(id: string) {
    localStorage.setItem(AppSettings.MERCHANT_ID, id)
  }

  getMerchantId() {
    return localStorage.getItem(AppSettings.MERCHANT_ID)
  }

  setAskGeneralInfo(isSet: boolean) {
    const _isSet = isSet == true ? 1 : 0
    localStorage.setItem(AppSettings.MERCHANT_ASK_GENERAL_INFO, JSON.stringify(_isSet))
  }

  getAskGeneralInfo() {
    let isSet: any = localStorage.getItem(AppSettings.MERCHANT_ASK_GENERAL_INFO)
    return JSON.parse(isSet) == 1 ? true : false
  }

  setAskGIAuth(isSet: boolean) {
    const _isSet = isSet == true ? 1 : 0
    localStorage.setItem(AppSettings.MERCHANT_ASK_GENERAL_INFO_LOGIN, JSON.stringify(_isSet))
  }

  getAskGIAuth() {
    let isSet: any = localStorage.getItem(AppSettings.MERCHANT_ASK_GENERAL_INFO_LOGIN)
    return JSON.parse(isSet) == 1 ? true : false
  }

  getLocalStorageByKey(key: string) {
    return localStorage.getItem(key)
  }

  setAuthKey(key: string) {
    // this._cookieService.put(AppSettings.AUTH_KEY, key)
    this._cookieService.setItem(environment.production ? AppSettings.AUTH_KEY : AppSettings.AUTH_KEY_DEV, key)
  }

  getAuthKey(): any {
    return this._cookieService.getItem(environment.production ? AppSettings.AUTH_KEY : AppSettings.AUTH_KEY_DEV)
  }

  setUserActivated(isActive: boolean) {
    if (typeof isActive !== 'boolean') isActive = false
    this._cookieService.setItem(AppSettings.COOKIE_USER_ACTIVATED, JSON.stringify(isActive))
  }

  getUserActivated(): string | boolean | null {
    let result: string | boolean | null | undefined = this._cookieService.getItem(AppSettings.COOKIE_USER_ACTIVATED)!
    if (typeof result !== 'string') return false
    return JSON.parse(result)
  }

  setAuthValidated(key: boolean) {
    const isValidated: string = key === true ? 'true' : 'false'
    this._cookieService.setItem(AppSettings.AUTH_VALIDATED, isValidated)
  }

  getAuthValidated() {
    return this._cookieService.getItem(AppSettings.AUTH_VALIDATED)
  }

  setAdminAuthKey(key: string) {
    this._cookieService.setItem(AppSettings.ADMIN_AUTH, key)
  }

  getAdminAuthKey() {
    return this._cookieService.getItem(AppSettings.ADMIN_AUTH)
  }

  setAuthValidateTime(datetime: any) {
    localStorage.setItem(AppSettings.AUTH_VALIDATE_COUNTER, datetime)
  }

  getAuthValidateTime() {
    return localStorage.getItem(AppSettings.AUTH_VALIDATE_COUNTER)
  }

  setSockToken(token: string) {
    this._cookieService.setItem(AppSettings.TOKEN_WEB_SOCKET, token)
  }

  getSockToken() {
    return this._cookieService.getItem(AppSettings.TOKEN_WEB_SOCKET)
  }

  setNewUser(isNew: boolean) {
    this._cookieService.setItem(AppSettings.NEW_USER, isNew.toString())
  }

  getNewUser() {
    const isNew = this._cookieService.getItem(AppSettings.NEW_USER)
    return isNew == 'true' ? true : false
  }

  setOnboardAccess(isAllowed: boolean) {
    this._cookieService.setItem(AppSettings.ONBOARD_ACCESS, isAllowed.toString())
  }

  getOnboardAccess() {
    const isAllowed = this._cookieService.getItem(AppSettings.ONBOARD_ACCESS)
    return isAllowed == 'true' ? true : false
  }

  get(key: string) {
    return localStorage.getItem(key)
  }

  set(key: string, val = '') {
    return localStorage.setItem(key, val)
  }

  forgotDevice() {
    localStorage.removeItem('deviceId_' + this.get('email'))
  }

  setAdminPrivilage(isAdmin: boolean) {
    localStorage.setItem(this.ADMIN_PRIVILAGE, JSON.stringify(isAdmin))
  }

  getAdminPrivilage(): any {
    let result: any = localStorage.getItem(this.ADMIN_PRIVILAGE)
    return JSON.parse(result)
  }

  logout() {
    localStorage.removeItem(AppSettings.TOKEN_STORAGE)
    localStorage.removeItem(AppSettings.USER_KEY)
    localStorage.removeItem(AppSettings.DEVICE_KEY)
  }

  // loggedIn(): boolean {
  //   return this.getAuthKey() !== null && this.getEmail() !== null
  // }

  reValidateAccount(): boolean {
    let info = this.getUserInfo()

    if (info) {
      this.email(info.email)
      this.setEmptyPassword(info.emptyPassword)
      this.setAuthValidated(info.validated)
      this.setBusinessName(info.name)
      this.setNameInitial(info.name)
      this.setAuthKey(info.authKey)
      this.setSockToken(info.socketToken)
      this.setNewUser(false)
      this.setAskGeneralInfo(info.basicIdentityNotComplete)
      this.setBusinessName(info.name)

      return true
    } else {
      return false
    }
  }

  setAppVersion() {
    localStorage.setItem(this.APP_VERSION, environment.version)
  }

  getAppVersion(): any {
    return localStorage.getItem(this.APP_VERSION) || undefined
  }

  appVersionChecker(): boolean {
    let result: boolean = false
    const localAppVersion = this.getAppVersion()
    const currAppVersion = environment.version

    if (localAppVersion !== undefined && localAppVersion === currAppVersion) {
      result = true
    } else {
      result = false
    }

    return result
  }

  getUserInfo(): any {
    if (this.isBrowser) {
      let result: any = localStorage.getItem(environment.production ? AppSettings.USER_INFO : AppSettings.USER_INFO_DEV)
      if (typeof result != 'undefined') {
        result = JSON.parse(result)
        return result
      } else {
        return false
      }
    }
  }

  setUserInfo(info: any) {
    localStorage.setItem(
      environment.production ? AppSettings.USER_INFO : AppSettings.USER_INFO_DEV,
      JSON.stringify(info)
    )
  }

  getToken() {
    return localStorage.getItem(AppSettings.USER_KEY) + '' + localStorage.getItem(AppSettings.DEVICE_KEY)
  }

  setUserKey(token: string) {
    this._cookieService.setItem('userKey', token)
  }

  setUser(user: UserContent) {
    // this._cookieService.putObject('user', user)
  }

  setDeviceKey(token: string) {
    this._cookieService.setItem('deviceKey', token)
  }

  getUserKey() {
    return this._cookieService.getItem('userKey')
  }

  getStoredUserList(): any[] {
    const storedUsers = localStorage.getItem('users')
    return storedUsers ? JSON.parse(storedUsers) : []
  }

  addStoredUserList(firstName: string, email: string) {
    const storedUsers = this.getStoredUserList()
    let exist = false
    for (const storedUser of storedUsers) {
      if (storedUser.email?.trim() === email.trim()) {
        exist = true
        storedUser.firstName = firstName
      }
    }

    if (!exist) {
      storedUsers.push({ firstName, email })
    }
    localStorage.setItem('users', JSON.stringify(storedUsers))
  }

  cdSource = new BehaviorSubject<any>(1)
  emptyPasswordObserver = this.cdSource.asObservable()

  setEmptyPassword(status: boolean) {
    let empty = status ? '1' : '0'
    localStorage.setItem(AppSettings.EMPTY_PASSWORD, empty)
    this.cdSource.next(1)
  }

  getEmptyPassword() {
    let empty: any = localStorage.getItem(AppSettings.EMPTY_PASSWORD)
    if (empty) {
      empty = parseInt(empty) ? true : false
    }
    return empty
  }

  userAuthHandler(data: UserInfo) {
    const newUserInfo = {
      email: data.email,
      emptyPassword: data.emptyPassword,
      validated: data.validated,
      name: data.name,
      nameInitial: data.email,
      authKey: data.authKey,
      socketToken: data.socketToken,
      newUser: false,
      basicIdentityNotComplete: data.basicIdentityNotComplete,
      roleName: data.role.name,
      merchantType: data.merchantType,
    }

    this.email(newUserInfo.email)
    this.setEmptyPassword(newUserInfo.emptyPassword)
    this.setUserActivated(data?.activated || false)
    this.setAuthValidated(newUserInfo.validated)
    this.setBusinessName(newUserInfo.name)
    this.setNameInitial(newUserInfo.email)
    this.setAuthKey(newUserInfo.authKey === null ? '' : newUserInfo.authKey)
    this.setSockToken(newUserInfo.socketToken)
    this.setNewUser(false)
    this.setAskGeneralInfo(newUserInfo.basicIdentityNotComplete)
    this.setUserInfo(newUserInfo)
    this.setRoleName(newUserInfo.roleName)
    setBusinessType(data.template)
    this._sidebarService.getCurrentMenuIdList(data.role.permissions)
    this.setAppVersion()
    setCurrTimezone(data?.timezone || '')
    setMerchantType(newUserInfo.merchantType)

    this._commonService.userInfo$.next(data)

    // Destroy selected business if exits
    try {
      this._businessService.destroyMonitoredBusiness()
    } catch (e) {}

    let setPhoneCode = CountryList.find((list) => list.countryId === data.phoneCountryCode)
    let setCurrency = CountryList.find((list) => list.currencyId === data.currency)
    let setCountryByRegion = CountryList.find((list) => list.countryId === data.region)

    if (setCountryByRegion?.countryId) {
      localStorage.setItem(AppSettings.COOKIE_COUNTRY, setCountryByRegion?.countryId!)
      localStorage.setItem(AppSettings.COOKIE_LANGUAGE, setCountryByRegion?.langId!)
    } else if (setCurrency?.countryId) {
      localStorage.setItem(AppSettings.COOKIE_COUNTRY, setCurrency?.countryId!)
      localStorage.setItem(AppSettings.COOKIE_LANGUAGE, setCurrency?.langId!)
    } else if (setPhoneCode?.countryId) {
      localStorage.setItem(AppSettings.COOKIE_COUNTRY, setPhoneCode?.countryId!)
      localStorage.setItem(AppSettings.COOKIE_LANGUAGE, setPhoneCode?.langId!)
    }

    if (setCurrency?.currencyId) {
      localStorage.setItem(AppSettings.COOKIE_CURRENCY, setCurrency.currencyId)
      localStorage.setItem(AppSettings.COOKIE_CURRENCY_LABEL, setCurrency.currencyLabel)
      localStorage.setItem(AppSettings.COOKIE_CURRENCY_SYMBOL, setCurrency.currencySymbol)
    } else if (setPhoneCode?.countryId) {
      localStorage.setItem(AppSettings.COOKIE_CURRENCY, setPhoneCode.currencyId)
      localStorage.setItem(AppSettings.COOKIE_CURRENCY_LABEL, setPhoneCode.currencyLabel)
      localStorage.setItem(AppSettings.COOKIE_CURRENCY_SYMBOL, setPhoneCode.currencySymbol)
    }

    // Set Country & Currency as Super Admin
    let adminPrivilage: any = localStorage.getItem(this.ADMIN_PRIVILAGE)
    adminPrivilage = JSON.parse(adminPrivilage)
    if (adminPrivilage === true) {
      const getCountry = localStorage.getItem(AppSettings.COOKIE_COUNTRY)
      const getCurrency = localStorage.getItem(AppSettings.COOKIE_CURRENCY)
      const getCurrencyLabel = localStorage.getItem(AppSettings.COOKIE_CURRENCY_SYMBOL)
      const getCurrencySymbol = localStorage.getItem(AppSettings.COOKIE_CURRENCY_SYMBOL)

      localStorage.setItem(AppSettings.COOKIE_SA_COUNTRY, getCountry!)
      localStorage.setItem(AppSettings.COOKIE_SA_CURRENCY, getCurrency!)
      localStorage.setItem(AppSettings.COOKIE_SA_CURRENCY_LABEL, getCurrencyLabel!)
      localStorage.setItem(AppSettings.COOKIE_SA_CURRENCY_SYMBOL, getCurrencySymbol!)
    }

    localStorage.setItem('firstTimeLogin', data.firstLogin.toString())

    // Set default store if exists
    if (adminPrivilage != true && data?.storeId) {
      this._businessRepository.getBusinessInfo(data.storeId, true, undefined).subscribe((responseBusiness) => {
        const newBusinessInfo: AdminBusinessDetail = responseBusiness.data
        newBusinessInfo.id = responseBusiness.data.merchantId
        newBusinessInfo.storeId = data.storeId

        this._businessService.setMonitoredBusiness(newBusinessInfo)
        window.location.replace(this._commonService.getDashboardUrl())
      })
    } else {
      this.setUserActivated(true)
      window.location.replace(this._commonService.getDashboardUrl())
    }
  }

  googleAuthHandler(data: any) {
    const newUserInfo = {
      email: data.email,
      emptyPassword: data.emptyPassword,
      validated: data.validated,
      name: data.name,
      nameInitial: data.email.email,
      authKey: data.authKey,
      socketToken: data.socketToken,
      newUser: false,
      basicIdentityNotComplete: data.basicIdentityNotComplete,
      roleName: data.role.name,
      merchantType: data.merchantType,
    }

    const lastLogin = getLastLogin() || ''
    this.email(data.email)
    this.setEmptyPassword(data.emptyPassword)
    this.setAuthValidated(data.validated)
    this.setUserActivated(data.activated)
    this.setBusinessName(data.name)
    this.setNameInitial(data.email)
    this.setAuthKey(data.authKey)
    this.setSockToken(data.socketToken)
    this.setAskGeneralInfo(data?.basicIdentityNotComplete)
    this.setAskGIAuth(data?.basicIdentityNotComplete)
    this.setRoleName(newUserInfo.roleName)
    this.setUserInfo(newUserInfo)
    this.setRoleName(newUserInfo.roleName)
    setBusinessType(data.template)
    this._sidebarService.getCurrentMenuIdList(data.role.permissions)
    this.setAppVersion()
    setMerchantType(newUserInfo.merchantType)

    // Re-apply monitored business
    try {
      this._businessService.destroyMonitoredBusiness()
      this._businessRepository.getBusinessInfo(data.storeId, true, undefined).subscribe((responseBusiness) => {
        const newBusinessInfo: AdminBusinessDetail = responseBusiness.data
        newBusinessInfo.id = responseBusiness.data.merchantId
        newBusinessInfo.storeId = data.storeId

        this._businessService.setMonitoredBusiness(newBusinessInfo)
        // this._businessService.setMonitoredBusiness(response.data.storeId);
      })
    } catch (e) {}

    if (
      data?.basicIdentityNotComplete &&
      data?.countMerchantChannel == 0 &&
      data?.countProducts == 0 &&
      data.socialRegisterFrom
    ) {
      this.setNewUser(true)
      this.setOnboardAccess(true)
      window.location.replace('/pages/onboarding/channel')
    } else {
      this.setNewUser(false)
      this.setOnboardAccess(false)

      setTimeout(() => {
        window.location.replace(this._commonService.getDashboardUrl())
      }, 3000)
    }

    let setPhoneCode = CountryList.find((list) => list.countryId === data.phoneCountryCode)
    let setCurrency = CountryList.find((list) => list.currencyId === data.currency)
    let setCountryByRegion = CountryList.find((list) => list.countryId === data.region)

    if (setCountryByRegion?.countryId) {
      localStorage.setItem(AppSettings.COOKIE_COUNTRY, setCountryByRegion?.countryId!)
      localStorage.setItem(AppSettings.COOKIE_LANGUAGE, setCountryByRegion?.langId!)
    } else if (setCurrency?.countryId) {
      localStorage.setItem(AppSettings.COOKIE_COUNTRY, setCurrency?.countryId!)
      localStorage.setItem(AppSettings.COOKIE_LANGUAGE, setCurrency?.langId!)
    } else if (setPhoneCode?.countryId) {
      localStorage.setItem(AppSettings.COOKIE_COUNTRY, setPhoneCode?.countryId!)
      localStorage.setItem(AppSettings.COOKIE_LANGUAGE, setPhoneCode?.langId!)
    }

    if (setCurrency?.currencyId) {
      localStorage.setItem(AppSettings.COOKIE_CURRENCY, setCurrency.currencyId)
      localStorage.setItem(AppSettings.COOKIE_CURRENCY_LABEL, setCurrency.currencyLabel)
      localStorage.setItem(AppSettings.COOKIE_CURRENCY_SYMBOL, setCurrency.currencySymbol)
    } else if (setPhoneCode?.countryId) {
      localStorage.setItem(AppSettings.COOKIE_CURRENCY, setPhoneCode.currencyId)
      localStorage.setItem(AppSettings.COOKIE_CURRENCY_LABEL, setPhoneCode.currencyLabel)
      localStorage.setItem(AppSettings.COOKIE_CURRENCY_SYMBOL, setPhoneCode.currencySymbol)
    }
  }
}

export interface AuthResponse extends Response {
  authKey: string
}
