/**
 * Interface for Analytic Item
 */

import {
  HttpResponseData,
  Pagination,
  PaginationData,
  PairedDate,
  ReqPagination,
  SortDirection,
} from '@core/models/common.model'

export interface AnalyticItemBestSellingPayload {
  customPeriodic: boolean
  endAt: number
  startAt: number
  storeId?: number
}

export interface AnalyticCategoryBestSellingPayload {
  customPeriodic: boolean
  dateEnd: number
  dateStart: number
  storeId?: number
}

export interface AnalyticItemBestSellingResponse {
  timestamp: number
  code: string
  status: number
  data: AnalyticItemBestSelling
}

export interface AnalyticCategoryBestSellingResponse {
  timestamp: number
  code: string
  status: number
  data: any
}

export interface AnalyticItemBestSelling {
  graphs: AnalyticItemBestSellingGraph[]
  startAt: number
  endAt: number
  setupOnStep: {
    addItems: boolean
    firstTime: boolean
    menuType: boolean
    settingAddress: boolean
    verifyEmail: boolean
  }
  emailVerified: boolean
  topItemOrder: AnalyticItemBestSellingData[]
  timezone: string
}

export interface AnalyticCategoryBestSelling {
  graphs: AnalyticCategoryBestSellingGraph[]
  startAt: number
  endAt: number
  setupOnStep: {
    addItems: boolean
    firstTime: boolean
    menuType: boolean
    settingAddress: boolean
    verifyEmail: boolean
  }
  emailVerified: boolean
  top5Category: AnalyticCategoryBestSellingData[]
  timezone: string
}

export interface AnalyticCategoryBestSellingData {
  itemCategory?: string
  qtyTotal?: number
  id: number
  itemName: string
  totalOrder: string
  colorProduct: string
}

export interface AnalyticItemBestSellingGraph {
  graph: AnalyticItemBestSellingGraphData[]
}

export interface AnalyticCategoryBestSellingGraph {
  graph: AnalyticCategoryBestSellingGraphData[]
}

export interface AnalyticItemBestSellingGraphData {
  data: AnalyticItemBestSellingGraphDataPerDate[]
  item: string
}

export interface AnalyticCategoryBestSellingGraphData {
  data: AnalyticCategoryBestSellingGraphDataPerDate[]
  item: string
}

export interface AnalyticItemBestSellingGraphDataPerDate {
  value: number
  date: number
}

export interface AnalyticCategoryBestSellingGraphDataPerDate {
  value: number
  date: number
}

export interface AnalyticItemBestSellingData {
  id: number
  itemName: string
  totalOrder: string
  colorProduct: string
}

export interface AnalyticItemDetailPayload {
  dateEnd: number
  dateStart: number
  pageNumber: number
  pageSize: number
  dataQuery?: string
  storeId?: number
}

export interface AnalyticItemDetailResponse {
  timestamp: number
  code: string
  status: number
  data: {
    content: AnalyticItemDetailContent[]
    pageable: {
      sort: AnalyticItemDetailSort
      offset: number
      pageNumber: number
      pageSize: number
      paged: boolean
      unpaged: boolean
    }
    last: boolean
    totalElements: number
    totalPages: number
    size: number
    number: number
    sort: AnalyticItemDetailSort
    first: boolean
    numberOfElements: number
    empty: boolean
  }
}

export interface AnalyticCategoryDetailResponse {
  timestamp: number
  code: string
  status: number
  data: {
    content: AnalyticCategoryDetailContent[]
    pageable: {
      sort: AnalyticItemDetailSort
      offset: number
      pageNumber: number
      pageSize: number
      paged: boolean
      unpaged: boolean
    }
    last: boolean
    totalElements: number
    totalPages: number
    size: number
    number: number
    sort: AnalyticItemDetailSort
    first: boolean
    numberOfElements: number
    empty: boolean
  }
}

export interface AnalyticItemDetailContent {
  id: number
  itemName: string
  category: string
  order: number
  netSales: number
  cost: number
  grossProfit: number
  modifier: AnalyticItemDetailModifier[]
}

export interface AnalyticCategoryDetailContent {
  category: string
  orderTotal: number
  graph: AnalyticCategoryDetailContentGraph[]
}

export interface AnalyticCategoryDetailContentGraph {
  itemName: string
  qtyTotal: number
}

export interface AnalyticItemDetailSort {
  sorted: boolean
  unsorted: boolean
  empty: boolean
}

export interface AnalyticItemDetailModifier {
  modifierName: string
  order: number
  netSales: number
  cost: number
  grossProfit: number
  itemOrder: number
}

export interface AnalyticItemDetailTable {
  name: string
  category: string
  order: number
  netSales: number
  cost: number
  grossProfit: number
  isParent: boolean
  parentName: string
  hasModifier: boolean
  itemOrder?: number
}

export interface AnalyticItemDetailPopup {
  visible: boolean
  data: AnalyticItemDetailTable | null
}

export interface AnalyticItemDetailTable {
  name: string
  category: string
  order: number
  netSales: number
  cost: number
  grossProfit: number
  isParent: boolean
  parentName: string
  hasModifier: boolean
}

export interface AnalyticItemDetailPopup {
  visible: boolean
  data: AnalyticItemDetailTable | null
}

/** =========================================  */

/**
 * Interface for Analytic Shift
 */

export interface AnalyticShiftList {
  id: number
  counter: number
  posName: string
  openedAt: number
  closedAt: number
  shiftStatus: string
  expectCashAmount: number
  actualCashAmount: number
  openedAtAlias: string
  closedAtAlias: string
  cashAmountClose: number
}

export interface AnalyticShiftPaginationData extends PaginationData {
  content: AnalyticShiftList[]
}

export interface AnalyticShiftPagination extends Pagination {
  data: AnalyticShiftPaginationData
}

export class ReqAnalyticShiftPagination {
  dataQuery!: string
  pageNumber!: number
  pageSize!: number
  sortDirection!: SortDirection
  dateStart?: string
  dateEnd?: string
  storeId?: number
}

export interface ShiftPaidData {
  amount: number
  note: string
  time: number
  userName: string
  timeAlias: string
}

export interface PaymentList {
  name: string
  amount: number
}
export interface AnalyticShiftDetail {
  storeName: string
  posDeviceId: number
  posDeviceName: string
  userOpenName: string
  userCloseName: string
  shiftStatus: string
  cashAmountOpen: number
  cashAmountClose: number
  cashPayment: number
  cashRefund: number
  cashPaidIn: number
  cashPaidInData: ShiftPaidData[]
  cashPaidOut: number
  cashPaidOutData: ShiftPaidData[]
  summaryGrossSale: number
  summaryRefund: number
  summaryDiscount: number
  summaryTaxes: number
  summaryServiceCharge: number
  openedAt: number
  closedAt: number
  counter: number
  cashExpectedAmount: number
  cashActualAmount: number
  summaryNetSales: number
  totalTendered: number
  openedAtAlias?: string
  closedAtAlias?: string
  summaryRounding: number
  tenderedPayments: PaymentList[]
  refundPayments: PaymentList[]
  summarySpecialCharge: number
}

export interface ResAnalyticShiftDetail extends HttpResponseData<AnalyticShiftDetail> {}

/** =========================================  */

/**
 * Interface for Analytic Shift
 */

// export interface ReqPRPagination extends ReqPagination {
export interface ReqPRPagination {
  storeId?: number
  dataQuery?: string
  pageSize?: number
  pageNumber?: number
  sortDirection?: 'ASC' | 'DESC' | undefined
  dateItemStart?: number
  dateItemEnd?: number
  transactionType?: number
  settlementStatus?: number
  statusOrder?: number
  sortBy?: string
}

export interface PaymentReconDate {
  orderDate: number
  amountCollected: number
  fee: number
  settledRefundAmount: number
  amountTransferred: number
  pendingAmount: number
  totalOrders: number
  orderDateAlias?: string
}

export interface PaymentReconTime {
  orderId: string
  transactionType: string
  orderAmount: number
  orderFee: number
  refundAmount: number
  payoutAmount: number
  settlementStatus: string
  netTransfer: number
}

export interface FormattedDate extends PairedDate {
  start: string
  end: string
}

/** =========================================  */

/**
 * Interface for Analytic Promotions
 */

export interface ReqPromoPagination {
  pageNumber?: number
  pageSize?: number
  promotionType?: string
  sortBy?: string
  sortDirection?: 'ASC' | 'DESC'
  dataQuery?: string
  dateItemStart?: number
  dateItemEnd?: number
}

export interface AnalyticPromotion {
  promotionsName: string
  type: string
  code: string
  discountApplied: number
  discountAmount: number
}

export interface AnalyticGraphPayload {
  storeId?: number
  dateStart: number
  dateEnd: number
  customPeriodic: boolean
  lang?: string
}

/** =========================================  */

/**
 * Interface for Analytic Payment Reconciliation
 */

export interface PaymentReconOrderStatus {
  code: string
  description: string
}

export const TIME_END_MILISEC: number = 86_399_000
export const HOUR_TO_MILISEC: number = 3_600_000
export const MIN_TO_MILISEC: number = 60_000
export const SEC_TO_MILISEC: number = 1_000

/** =========================================  */

export interface AnalyticOrderMenuGraphResponse {
  timestamp: number
  code: string
  status: number
  data: {
    graphs: AnalyticOrderMenuGraph[]
    startAt: number
    endAt: number
    emailVerified: boolean
    pastStartAt: number
    pastEndAt: number
  }
}

export interface AnalyticOrderMenuGraph {
  type: string
  value: number
  pastValue: number
  diffValue: number
  diffValuePercent: number
  diffValuePercentData: number
  data: AnalyticOrderMenuPerDate[]
  pastData: AnalyticOrderMenuPerDate[]
}

export interface AnalyticOrderMenuPerDate {
  value: number
  date: number
}

export enum PeriodCode {
  PERIOD_WEEK = 'week',
  PERIOD_2_WEEK = 'twoWeek',
  PERIOD_MONTH = 'month',
  PERIOD_CUSTOM = 'custom',
}

export const ANALYTIC_ORDER_PATH: string = 'order'

export interface DateDiffFormatted {
  label: string
  value: number
}

export class PeriodStructure {
  code!: PeriodType
  name!: string
}

export type PeriodType =
  | PeriodCode.PERIOD_WEEK
  | PeriodCode.PERIOD_2_WEEK
  | PeriodCode.PERIOD_MONTH
  | PeriodCode.PERIOD_CUSTOM

export interface AnalyticOrderMenuPayload {
  startAt: number
  endAt: number
  storeId?: number
  customPeriodic: boolean
}

export class TimeFormat {
  start!: number
  end!: number
}

export const DAY_IN_TIMESTAMP: number = 86400000
export const WEEK_IN_TIMESTAMP: number = 604800000
export const MONTH_IN_TIMESTAMP: number = 2678400000

export interface AnalyticOrderMenuDetailPayload {
  dataQuery?: string
  pageNumber: number
  pageSize: number
  storeId?: number
  dateStart: number
  dateEnd: number
}

export interface AnalyticOrderMenuDetail {
  timestamp: number
  code: string
  status: number
  data: AnalyticOrderMenuDetailData
}

export interface AnalyticOrderMenuDetailData {
  content: AnalyticOrderMenuDetailContent[]
  pageable: AnalyticOrderMenuPage
  last: boolean
  totalElements: number
  totalPages: number
  size: number
  number: number
  sort: AnalyticOrderMenuDetailSort
  first: boolean
  numberOfElements: number
  empty: boolean
}

export interface OrderContentV2 {
  orderModelList: AnalyticOrderMenuDetailContent[]
  sumNetSales: number
  sumCost: number
  sumGrossProfit: number
  sumTotalOrder: number
}

export interface AnalyticOrderMenuDetailDataV2 {
  content: OrderContentV2[]
  pageable: AnalyticOrderMenuPage
  last: boolean
  totalElements: number
  totalPages: number
  size: number
  number: number
  sort: AnalyticOrderMenuDetailSort
  first: boolean
  numberOfElements: number
  empty: boolean
}

export interface AnalyticOrderMenuDetailContent {
  id: number
  orderName: string
  orderType: string
  orderTime: number
  netSales: number
  cost: number
  grossProfit: number
  refund: number
  discount: number
  showDetail?: boolean
}

export interface AnalyticOrderMenuPage {
  sort: AnalyticOrderMenuDetailSort
  offset: number
  pageNumber: number
  pageSize: number
  paged: boolean
  unpaged: boolean
}

export interface AnalyticOrderMenuDetailSort {
  sorted: boolean
  unsorted: boolean
  empty: boolean
}

export interface DateDiffFormatted {
  label: string
  value: number
}

export interface PaymentStatus {
  code: number
  name: string
  availableStore: boolean
}
