import { AppSettings } from '@configs/app.setting'
import { Timezone } from '@models/common.model'
import { VIEW_MODE_DESKTOP } from '@models/view.model'
import { TranslateService } from '@ngx-translate/core'
import { CustomCookieService } from '@services/cookie/cookie.service'
import { environment } from 'src/environments/environment'

const TIMEZONE_LIST: string = AppSettings.TIMEZONE_LIST
const TIMEZONE_CURRENT: string = 'timezone-current'
const TIMEZONE_OFFSET: string = 'timezone-offset'
const SCREEN_VIEW: string = 'screen-view'

export function setTimezoneList(timezoneList: Timezone[] | any) {
  if (!Array.isArray(timezoneList)) return
  localStorage.setItem(TIMEZONE_LIST, JSON.stringify(timezoneList))
  const currTimezone = getCurrTimezone()
  if (currTimezone.length > 0 && isTimezoneOffsetExist(currTimezone)) {
    setTimezoneOffset(currTimezone)
  } else if (currTimezone.length > 0 && !isTimezoneOffsetExist(currTimezone)) {
    setTimezoneOffsetByCode(currTimezone)
  }
}

export function getTimezoneList(): Timezone[] | undefined {
  let data: any = localStorage.getItem(TIMEZONE_LIST) || undefined
  if (typeof data === 'undefined') return undefined
  data = JSON.parse(data)
  return data as Timezone[]
}

export function setCurrTimezone(timezone: string = '') {
  localStorage.setItem(TIMEZONE_CURRENT, JSON.stringify(timezone))
  if (timezone !== '') {
    setTimezoneOffset(timezone)
  }
}

export function setCurrTimezoneByCode(timezoneCode: string = '') {
  localStorage.setItem(TIMEZONE_CURRENT, JSON.stringify(timezoneCode))
  if (timezoneCode !== '') {
    setTimezoneOffsetByCode(timezoneCode)
  }
}

export function getCurrTimezone(): string {
  let data: any = localStorage.getItem(TIMEZONE_CURRENT) || undefined
  if (typeof data === 'undefined') return ''
  return JSON.parse(data)
}

function setTimezoneOffset(timezone: string) {
  const timezoneList: Timezone[] | undefined = getTimezoneList()
  if (typeof timezoneList === 'undefined') return
  let offset: string = '0'
  timezoneList.map((dTimezone) => {
    dTimezone.utc.map((utc: string) => {
      if (utc === timezone) {
        if (dTimezone.offset > 0) {
          offset = `+${dTimezone.offset}`
        } else {
          offset = dTimezone.offset.toString()
        }
      }
    })
  })

  localStorage.setItem(TIMEZONE_OFFSET, JSON.stringify(offset))
}

function isTimezoneOffsetExist(timezone: string): boolean {
  let result: boolean = false
  const timezoneList: Timezone[] | undefined = getTimezoneList()

  if (typeof timezoneList === 'undefined') return result
  let offset: string = '0'
  timezoneList.map((dTimezone) => {
    dTimezone.utc.map((utc: string) => {
      if (utc === timezone) {
        if (dTimezone.offset > 0) {
          offset = `+${dTimezone.offset}`
        } else {
          offset = dTimezone.offset.toString()
        }
      }
    })
  })

  if (offset !== '0') result = true

  return result
}

function setTimezoneOffsetByCode(timezoneCode: string) {
  const timezoneList: Timezone[] | undefined = getTimezoneList()
  if (typeof timezoneList === 'undefined') return
  let offset: string = getTimezoneOffset()
  timezoneList.map((dTimezone) => {
    if (dTimezone.code === timezoneCode) {
      if (dTimezone.offset > 0) {
        offset = `+${dTimezone.offset}`
      } else {
        offset = dTimezone.offset.toString()
      }
    }
  })
  localStorage.setItem(TIMEZONE_OFFSET, JSON.stringify(offset))
}

export function getTimezoneOffset(): string {
  let data: any = localStorage.getItem(TIMEZONE_OFFSET) || undefined

  if (typeof data === 'undefined') return '0'
  return JSON.parse(data)
}

// Screen View
export function setScreenView(mode: string) {
  localStorage.setItem(SCREEN_VIEW, JSON.stringify(mode))
}

export function getScreenView(): string {
  let data: any | undefined = localStorage.getItem(SCREEN_VIEW) || undefined
  if (typeof data === 'undefined') return VIEW_MODE_DESKTOP
  return JSON.parse(data)
}

/**
 *
 * @param title
 * @param logArgument can be anything you want to log in console (string, object, array, etc)
 *
 * you can use this function to log something in console
 */
export function log({ title = 'log', logArgument }) {
  if (environment.localhost) {
    console.log(title ?? logArgument.toString(), logArgument)
  }
}


/**
 * @param exceptionList array string of localstorage key to exclude while removing localstorage 
 */
export function removesLocalstorage(exceptionList: string[]) {
  // Removes localstorage with exception
  Object.entries(localStorage).map((key) => {
    let isExists: boolean = exceptionList.some(exception => exception === key[0])
    
    if (!isExists) {
      delete localStorage[key[0]]
    }
  })
}

/**
 * Get current Lang Id
 * @returns string, 2 digit of Lang Id
 * @param cookieService put cookie service from CustomCookieService
 * @param translateService put translate service from TranslateService (angular lib)
 */
export function getCurrLangId(cookieService: CustomCookieService, translateService: TranslateService): string {
  let result = cookieService.getItem(AppSettings.COOKIE_LANGUAGE) || translateService.getDefaultLang()
  result = result.substring(0, 2)

  return result
}
