import { BreakpointObserver } from '@angular/cdk/layout'
import { DOCUMENT, isPlatformBrowser } from '@angular/common'
import {
  AfterContentChecked,
  AfterContentInit,
  Component,
  Inject,
  OnInit,
  PLATFORM_ID,
  Renderer2,
  ViewEncapsulation,
} from '@angular/core'
import { CommonRepositoryService } from '@repository/common-repository/common-repository.service'
import { UserRepository } from '@repository/user-repository/user-repository.service'
import { CommonService } from '@services/common/common.service'
import { ViewService } from '@services/view/view.service'
import { setTimezoneList } from '@core/utils/app-stuff'
import { TranslateService } from '@ngx-translate/core'
import { AuthService } from '@services/auth/auth.service'

@Component({
  selector: 'app-root',
  encapsulation: ViewEncapsulation.None,
  template: `
    <!-- <app-error-page [class.d-none]="!setErrorPage"></app-error-page> -->
    <router-outlet *ngIf="isBrowser"></router-outlet>
    <ngx-spinner type="ball-scale-multiple" color="#73000c" size="medium"></ngx-spinner>
  `,
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, AfterContentInit, AfterContentChecked {
  constructor(
    @Inject(PLATFORM_ID) platformId: Object,
    @Inject(DOCUMENT) private document: any,
    private _viewService: ViewService,
    private _commonService: CommonService,
    private _commonRepository: CommonRepositoryService,
    private bpo: BreakpointObserver,
    private userRepository: UserRepository,
    private _translateService: TranslateService,
    private _authService: AuthService
  ) {
    this.isBrowser = isPlatformBrowser(platformId)
    this.errorPageListener()
    // this.setLiveChat()
  }

  setErrorPage: boolean = this._viewService.getErrorPage() || false
  title = 'ideku'
  deviceInfo: any = null
  isBrowser: boolean = false
  loadLiveChatApi!: Promise<any>

  ngOnInit(): void {
    this.setTimezoneList()
    this.setLiveChat()
  }

  ngAfterContentInit(): void {}

  ngAfterContentChecked(): void {}

  errorPageListener() {
    this._commonService.errorPage.subscribe((response) => {
      this.setErrorPage = this._viewService.getErrorPage() || false
    })
  }

  setTimezoneList() {
    this._commonRepository.timezoneList().subscribe((response) => {
      if (response.status === 200) {
        setTimezoneList(response.data)
      }
    })
  }

  // setScreenView() {
  //   // Check Screen View
  //   const styleToCheck = '(min-width: 800px)'
  //   //Listen to changes in screen width
  //   this.bpo.observe([styleToCheck]).subscribe((result) => {
  //     if (result.matches) {
  //       this.screenView = VIEW_MODE_DESKTOP
  //     } else {
  //       this.screenView = VIEW_MODE_MOBILE
  //     }
  //   })
  // }

  setLiveChat(): void {
    if (this._authService.getAdminPrivilage() === null) {
      const script = document.createElement('script')

      script.type = 'text/javascript'
      script.defer = true
      script.src =
        'https://salesiq.zoho.com/widget?widgetcode=siq292a1d42c7c33bb2a1cb85f6c0e2591158ad06b007517d6516334413dba6f374b801a27b6b25342664d84d3ecdde10f9'
      script.id = 'zsiqscript'

      document.head.appendChild(script)

      let zSiqWidget = document.createElement('div')
      zSiqWidget.setAttribute('id', 'zsiqwidget')
      document.body.appendChild(zSiqWidget)
    }
  }
}
