import { PhoneCode } from '@core/models/common.model'
import { TempPhoneList } from './phone-code-list'

export function titleCase(str: string) {
  let lowerCase = str?.toLocaleLowerCase()
  return `${str.charAt(0).toLocaleUpperCase()}${str.substring(1, str.length)}`
}

export function splitBy(str: string, indicator: string, replace: string) {
  return str?.split(indicator).join(replace)
}

export function splitBySpace(str: string) {
  return str?.split(' ').join('-').toLocaleLowerCase()
}

export function noSpecialCharacter(str: string) {
  let dString = str?.trim()
  dString = dString?.replace(/[^a-zA-Z0-9._-]/g, '')

  return dString || ''
}

export function convertToLink(str: string) {
  let convertHypen = splitBySpace(str?.trim())
  const noSpecialChar = convertHypen?.replace(/[^a-zA-Z0-9._-]/g, '')
  return noSpecialChar?.toLocaleLowerCase() || ''
}

export function convertToString(value: any): string {
  let convertedValue = ''
  convertedValue = value as string

  return convertedValue
}

// Convert phone number for region: Indonesia
export function convertPhoneNumber(value: string): string {
  let replacedNumber: string = value
  if (value.charAt(0) == '0') {
    replacedNumber = `62`
    return replacedNumber
  } else {
    return replacedNumber
  }
}

/**
 *
 * @param value whole phone number
 * @returns Array of PhoneCodeList
 */
export function separatePrefixPhoneNumber(value: string): { codeNumber: string; number: string } {
  let result: { codeNumber: string; number: string } = { codeNumber: '', number: '' }
  let rawPhoneList: string | PhoneCode[] = localStorage.getItem('phoneCode') || TempPhoneList
  let phoneCodeList: PhoneCode[] = []

  // Prevention of undefined phone code list
  if (typeof rawPhoneList === 'string') {
    phoneCodeList = JSON.parse(rawPhoneList)
  } else {
    phoneCodeList = rawPhoneList
  }

  let indexCode = phoneCodeList.findIndex((phoneCode) => value.startsWith(phoneCode.phoneInitial.toString()))

  if (indexCode != -1) {
    result.codeNumber = phoneCodeList[indexCode].phoneInitial.toString()
    result.number = value.substring(phoneCodeList[indexCode].phoneInitial.toString().length)
  } else {
    result.number = value
  }

  return result
}

// Convert string Time to only 4 digit with ":" as separator
export function convertTime(dTime: string): string {
  return dTime.substring(0, 5)
}

// Convert month into 3digit string month. start with 01
export function convertMonth(month: string): string {
  switch (month) {
    case '01':
      return 'Jan'
    case '02':
      return 'Feb'
    case '03':
      return 'Mar'
    case '04':
      return 'Apr'
    case '05':
      return 'Mei'
    case '06':
      return 'Jun'
    case '07':
      return 'Jul'
    case '08':
      return 'Agus'
    case '09':
      return 'Sep'
    case '10':
      return 'Okt'
    case '11':
      return 'Nov'
    case '12':
      return 'Des'
    default:
      return month
  }
}

// Convert string Date into DD M(string) YYYY, HH:MM
export function convertFullDate(dDate: string, isTimeExcluded?: boolean): string {
  const setDate = dDate.substring(8, 10)
  const setMonth = convertMonth(dDate.substring(5, 7))
  const setYear = dDate.substring(0, 4)
  const setTime = isTimeExcluded ? '' : dDate.substring(11, 16)

  return `${setDate} ${setMonth} ${setYear}, ${setTime}`
}

// Convert Timestamp into xx MMM YYYY, mm:ss
export function convertTimeStamp(setStamp: number, isTimeExcluded: boolean = true): string {
  const dDate = new Date(setStamp)
  let convertedDate = `${dDate.toString().substring(8, 10)} ${dDate.toString().substring(4, 7)} ${dDate
    .toString()
    .substring(11, 15)}`
  if (!isTimeExcluded) {
    convertedDate = `${convertedDate}, , ${dDate.toString().substring(16, 21)}`
  }
  return convertedDate
}

// Convert Currency
export function convertToRp(amount: string, fraction: number = 0, isRemovePrefix: boolean = false): string {
  amount = amount.replace(/[.Rpa-zA-Z]/gi, '')

  if (!Number(amount)) return ''
  if (amount.length == 0) return ''

  let result = new Intl.NumberFormat('id-ID', {
    style: 'currency',
    currency: 'IDR',
    maximumFractionDigits: fraction,
  }).format(Number(amount))

  if (isRemovePrefix) {
    result = result.replace(/[Rp]/gi, '')
  }

  return result.trim()
}

export function convertCurrency(
  amount: number,
  fraction: number = 0,
  isRemovePrefix: boolean = false,
  currency: string
): string {
  if (!Number(amount)) return ''

  // let result = new Intl.NumberFormat('id-ID', {
  //   style: 'currency',
  //   currency: currency,
  //   maximumFractionDigits: fraction,
  // }).format(Number(amount))

  amount = Number(amount.toString().replace(currency, '').replaceAll(',', ''))
  let result = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: currency,
    maximumFractionDigits: fraction,
    currencyDisplay: 'code',
  }).format(amount)

  if (isRemovePrefix) {
    result = result.replace('/[' + currency + ']/gi', '')
  }

  return result === '' ? '0' : result.trim()
}

export function convertDecimal(amount: string, fraction: number = 0): string {
  if (amount === undefined) return ''
  let convertedAmount = Number(convertNumberWithDot(amount.toString()))
  if (isNaN(convertedAmount)) return ''

  let result = new Intl.NumberFormat('en-US', {
    style: 'decimal',
    maximumFractionDigits: fraction,
  }).format(convertedAmount)

  return result === '' ? '0' : result.trim()
}

// Clear string + character, number only
export function convertNumberOnly(value: string) {
  return value.replace(/[^0-9.]/g, '')
}

// Convert to decimal, dot is allowed
export function convertNumberWithDot(value: string) {
  if (value == undefined) return ''
  return value.replace(/[^0-9]/g, '')
}

export function convertFileNameFormat(value: string) {
  return value.toLocaleLowerCase().replace(/[^a-zA-Z0-9._-]/g, '')
}

export function randomString(length: number = 5) {
  let result = ''
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
  const charactersLength = characters.length
  let counter = 0
  while (counter < length) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength))
    counter += 1
  }
  return result
}

export function convertRemoveUrlSymbol(value: string): string {
  return value.replaceAll(/[/\\#\?%&]/g, '')
}