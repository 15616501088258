import { AppSettings } from '@configs/app.setting'
import { CountryListUtil } from '@core/models/common.model'

export const CountryList: CountryListUtil[] = [
  {
    name: AppSettings.COUNTRY_INDONESIA,
    countryId: 'ID',
    currencyId: 'IDR',
    langId: 'id_ID',
    currencyLabel: 'IDR (Rp)',
    currencySymbol: 'IDR',
    locale: 'id-ID',
  },
  {
    name: AppSettings.COUNTRY_SINGAPORE,
    countryId: 'SG',
    currencyId: 'SGD',
    langId: 'en_US',
    currencyLabel: 'SGD ($)',
    currencySymbol: 'SGD',
    locale: 'en-SG',
  },
  {
    name: AppSettings.COUNTRY_CAMBODIA,
    countryId: 'KH',
    currencyId: 'KHR',
    langId: 'km_KH',
    currencyLabel: 'KHR (៛)',
    currencySymbol: 'KHR',
    locale: 'km-KH',
  },
  {
    name: AppSettings.COUNTRY_CHINESE,
    countryId: 'CN',
    currencyId: 'CNY',
    langId: 'zh_HANS',
    currencyLabel: 'CNY (¥)',
    currencySymbol: 'CNY',
    locale: 'zh-HANS',
  },
]

export function getCountryData(countryName: string): CountryListUtil | null {
  let result: CountryListUtil | null = null
  for (let idx = 0; idx < CountryList.length; idx++) {
    if (CountryList[idx].name === countryName) {
      result = CountryList[idx]
      break
    }
  }

  return result
}

export function getCountryDataByCountryId(countryId: string): CountryListUtil | null {
  let result: CountryListUtil | null = null
  for (let idx = 0; idx < CountryList.length; idx++) {
    if (CountryList[idx].countryId === countryId) {
      result = CountryList[idx]
      break
    }
  }

  return result
}

export function getCountryDataByCurrencyId(currencyId: string): CountryListUtil | null {
  let result: CountryListUtil | null = null
  for (let idx = 0; idx < CountryList.length; idx++) {
    if (CountryList[idx].currencyId === currencyId) {
      result = CountryList[idx]
      break
    }
  }

  return result
}

export function getCountryLocale(country: string): string {
  return CountryList.filter((list: CountryListUtil) => list.countryId === country)[0].locale
}
